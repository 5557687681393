import React from 'react';
import MedalOne from '@/assets/images/home/hero/medal_1.png';
import MedalTwo from '@/assets/images/home/hero/medal_2.webp';
import MedalThree from '@/assets/images/home/hero/medal_3.png';
import HeroImageMobile from '@/assets/images/home/hero/hero_m.png';
import HeroImageDesktop from '@/assets/images/home/hero/hero_d.png';
import CardList from '@/components/element/home/hero/CardList';
import StandLogo from '@/assets/images/home/hero/stand_logo.svg';
import Glow from '@/components/global/Glow';
import Any from '@/components/global/Any';

const Medals: { src: string, alt: string }[] = [
    {
        src: MedalOne,
        alt: '日本最大級情報10万件以上'
    },
    {
        src: MedalTwo,
        alt: '日本全国1700自治体対応'
    },
    {
        src: MedalThree,
        alt: '利用者の満足度97％以上'
    }
];

const Class = {
    Glow: `
        w-[800px] opacity-80
        md:w-[1300px] md:opacity-60
        lg:w-[1500px] lg:opacity-70
    `
}

const Delay = 250;

const Hero = () => {
    return (
        <div
            className='
                w-full flex justify-center
            '>
            <div
                className='
                    w-full relative flex justify-center
                '>
                <div
                    className='
                        absolute w-1/2 h-full z-[-1] top-0 left-1/2 bg-[#F0FBFC] rounded-bl-md hidden
                        md:block
                    '>
                </div>
                <div
                    className='
                        w-full max-w-[85rem] flex flex-col items-center
                        md:flex-row justify-center
                    '>
                    <div
                        className='
                            w-full max-w-fit relative
                            md:flex-1 md:min-h-[90%]
                            lg:min-h-[95%]
                        '>
                        <div
                            className='h-full absolute z-[-1] top-0 right-0'>
                            <div className='h-[70px]'></div>
                            <div
                                className='
                                    h-[calc(100%-70px)] -translate-x-[10px]
                                '>
                                <img
                                    src={StandLogo}
                                    alt="stand-logo"
                                    className='h-full'
                                />
                            </div>
                        </div>
                        <div
                            className='
                                w-full relative flex flex-col items-center gap-y-8 pt-[120px] px-5
                                md:items-start md:gap-y-6 md:pl-10 md:pt-[150px]
                                lg:gap-y-7 lg:pt-[155px]
                            '>
                            <div
                                className='
                                    w-full flex flex-col items-center gap-y-8
                                    md:gap-y-6
                                    lg:gap-y-7
                                '>
                                <Any
                                    className='
                                        w-full text-center text-[16px] text-[#012B32]
                                        md:text-start md:text-[2.151vw]
                                        lg:text-[28px] lg:whitespace-nowrap
                                    '
                                    instant
                                    from='translate-x-[-20px] opacity-0'
                                    to={[
                                        {
                                            state: 'translate-x-0 opacity-100',
                                            duration: 1000,
                                            easing: 'outQuad'
                                        }
                                    ]}>
                                    {/* 公的保険・公的支援情報検索サービス */}
                                   <h1>補助金・助成金 公的支援 検索活用サービス</h1>
                                </Any>
                                <Any
                                    className='
                                        w-full max-w-[350px]
                                        md:max-w-none
                                    '
                                    instant
                                    from='translate-x-[-20px] opacity-0'
                                    to={[
                                        {
                                            state: 'translate-x-0 opacity-100',
                                            duration: 1000,
                                            easing: 'outQuad',
                                            delay: Delay
                                        }
                                    ]}>
                                    <div
                                        className='
                                            w-full
                                            md:hidden
                                        '>
                                        <img
                                            src={HeroImageMobile}
                                            alt='hero'
                                            sizes='100%'
                                        />
                                    </div>
                                    <div
                                        className='
                                            w-full hidden
                                            md:block
                                        '>
                                        <img
                                            src={HeroImageDesktop}
                                            alt='hero'
                                            sizes='100%'
                                        />
                                    </div>
                                </Any>
                            </div>
                            <Any
                                className='
                                    w-full text-center text-[13px] text-[#012B32]
                                    md:text-start md:text-[1.564vw]
                                    lg:text-[1.564vw]
                                    xl:text-[20px]
                                '
                                instant
                                from='translate-x-[-20px] opacity-0'
                                to={[
                                    {
                                        state: 'translate-x-0 opacity-100',
                                        duration: 1000,
                                        easing: 'outQuad',
                                        delay: Delay * 2
                                    }
                                ]}>
                                {/* 『8LIFE』は、公的保険・公的支援の情報検索サービス。<br />
                                補助金・助成金も含め、<br className='md:hidden'/>
                                全国2,000箇所・12万件以上の情報を集約しています。 */}
                                {/* 『8LIFE』は、補助金・助成金・公的保険などの様々な公的支援制度の検索や申請相談が行えるサービスです。
                                個人／事業者向けに全国各地の約2000機関・12万件以上の情報を集約しています。 */}
                                『8LIFE』は、補助金・助成金・公的保険などの様々な<br className='md:hidden' />
                                公的支援制度の検索や申請相談が行えるサービスです。<br />
                                個人／事業者向けに全国各地の約2000機関・12万件以上の情報を集約しています。
                            </Any>
                            <div
                                className='
                                    flex gap-x-5 py-2.5
                                    md:gap-x-7  md:py-5
                                    lg:gap-x-9
                                '>
                                {
                                    Medals.map((medal, index) =>
                                        <Any
                                            key={index}
                                            className='
                                                w-full max-w-[100px]
                                                md:max-w-[128px]
                                                lg:max-w-[160px]
                                            '
                                            instant
                                            from='translate-x-[-20px] opacity-0'
                                            to={[
                                                {
                                                    state: 'translate-x-0 opacity-100',
                                                    duration: 1000,
                                                    easing: 'outQuad',
                                                    delay: Delay * 3 + Delay * index
                                                }
                                            ]}>
                                            <img
                                                src={medal.src}
                                                alt={medal.alt}
                                                sizes='100%'
                                            />
                                        </Any>
                                    )
                                }
                            </div>
                        </div>
                        <Glow
                            size='20'
                            className={`
                                ${Class.Glow} top-0 left-0
                                -translate-x-[55%] -translate-y-[25%]
                                md:-translate-y-[40%] md:-translate-x-[58%]
                            `}>
                        </Glow>
                    </div>

                    <div
                        className='
                            w-full hidden
                            md:block md:flex-1
                        '>
                        <CardList
                            firstDelay={Delay}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;